import { getAllResellerCamera } from '@api/services/reseller.camera.service';
import useDataQuery from '@hooks/use-data-query';

const useResellerCameraListData = () => {
    const resellerCameraListQuery = useDataQuery(['reseller', 'all-camera'], () => getAllResellerCamera());
    return {
        data: resellerCameraListQuery.data,
        isLoading: resellerCameraListQuery.isLoading,
    };
};

export default useResellerCameraListData;
