import { IUser } from '@api/models/user.model';
import { CrossIcon, ReloadIcon } from '@icon/index';
import { DatePicker } from '@library/datepicker';
import { FormHelperText, Input, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { generate } from 'generate-password-browser';
import http from 'httpConfig/http';
import CustomSwitch from 'modules/shared/components/FormFields/CustomSwitch';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { useState } from 'react';
import { ColorResult } from 'react-color';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { roleOption, statusOption, themeOption, timezoneOption } from '../../helpers/user.utils';
import useUniqueMutation from '../../hooks/useUniqueMutation';

import { Button, Card, Chip, Drawer, LinearProgress } from '@hyperflake/react-ui-library';
import ColorPicker from 'modules/shared/components/ColorPicker/ColorPicker';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import useUserEditForm from 'modules/users/hooks/useUserEditForm';
import PhoneDetails from '../PhoneDetails/PhoneDetails';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { PermissionEnum } from '@api/enum/permission.enum';
import UserUpdateDp from '../UserUpdateDp/UserUpdateDp';
import useUserData from 'modules/users/hooks/useUserData';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import ExternalProjectList from '../ExternalProjectList/ExternalProjectList';
import ProjectAccessDrawerForm from '../ProjectAccessDrawerForm/ProjectAccessDrawerForm';
import ProjectAccessListForm from '../ProjectAccessListForm/ProjectAccessListForm';

interface IUserEditFromProps {
    // open: boolean;
    // userId: string;
    // onClose: () => void;
    // onUserUpdate: (user: IUser) => void;
    // user: IUser;
}

const UserEditFrom = (props: IUserEditFromProps) => {
    // const {
    //     userId,
    //     onClose,
    //     open,
    //     onUserUpdate,

    //     // user
    // } = props;
    const { clientId, userId } = useParams();

    const [tagHolder, setTagHolder] = useState('');

    const { uniqueEmailMutation, uniqueUsernameMutation } = useUniqueMutation();

    const editUserForm = useUserEditForm(clientId, userId);

    const hasPhoneNumber = (data: any) =>
        data.phone && data.phone.number && data.phone.dialCode && data.phone.countryCode;

    const handleSubmit = async (values: any) => {
        try {
            const data = await editUserForm.submit(values);
            toast.success('User Updated Successfully');
            // onUserUpdate(data);

            // onClose();
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };

    const userData = useUserData(clientId, userId);

    const handleUserDpUpdate = (user: IUser) => {
        userData.setData(user);
        // onUserUpdate(user);
    };

    const user = userData.data;

    const updatePasswordMutation = useMutation(async (password: string) => {
        const res = await http.put(
            `${
                import.meta.env.VITE_APP_API_URL
            }/${getResellerIdFromRedux()}/clients/${clientId}/users/${userId}/password`,
            {
                password,
            }
        );
        return res;
    });

    /**
     * Runs mutation to update password
     * return true on mutation error
     * @param {String} newPassword
     * @return {Boolean}
     */
    const handleUpdatePassword = async (newPassword: string): Promise<boolean> => {
        try {
            await updatePasswordMutation.mutateAsync(newPassword);
            toast.success('Password Updated');
            return false;
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong while updating password');
            return true;
        }
    };

    const generatePassword = () => {
        const password = generate({
            length: 8,
            uppercase: false,
            numbers: true,
        });
        return password;
    };

    const { hasPermission } = useAuthPermission();
    // const handleDpUrlUpdate = (dpUrl: string) => {
    //     onUserUpdate({ ...editUserForm.UserData, dpUrl });
    //     console.log('dpUrl', dpUrl);
    // };
    // console.log('user', user);

    // if (isLoading) return <DrawerFormLoading formTitle="Edit User" />;

    return (
        // <Drawer open={open} onClose={onClose} title="Edit User">
        <PageTemplate>
            <PageHeading>Edit User</PageHeading>
            {editUserForm.userLoading ? (
                <LinearProgress color="primary" />
            ) : (
                <div>
                    <Card className="mt-4">
                        <Card.Header>Profile Pictures</Card.Header>
                        <Card.Body>
                            <UserUpdateDp user={user} clientId={clientId} onDpUrlUpdate={handleUserDpUpdate} />
                        </Card.Body>
                    </Card>
                    <Formik
                        initialValues={editUserForm.initialValues}
                        enableReinitialize
                        validationSchema={editUserForm.schema}
                        validateOnChange={false}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, values, isValidating, setFieldError, setFieldTouched }) => (
                            <Form>
                                <fieldset disabled={!hasPermission(PermissionEnum.USER_UPDATE)}>
                                    <Card className="mt-4 ">
                                        <Card.Header>Basic Details</Card.Header>
                                        <Card.Body>
                                            <div>
                                                <div className="grid grid-cols-2 mt-4 gap-4 ">
                                                    {/* name */}
                                                    <div className="">
                                                        <Label>
                                                            Name <RequiredMark />
                                                        </Label>
                                                        <FormikInput type="text" name="name" />
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="name" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* username */}
                                                    <div className="">
                                                        <Label>
                                                            Username <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2 items-center">
                                                            <FormikInput type="text" name="username" />
                                                            {uniqueUsernameMutation.isLoading && (
                                                                <CircularProgress size={14} />
                                                            )}
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="username" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* email */}
                                                    <div className="">
                                                        <Label>
                                                            Email <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2 items-center">
                                                            <FormikInput type="email" name="email" />
                                                            {uniqueEmailMutation.isLoading && (
                                                                <CircularProgress size={14} />
                                                            )}
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="email" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* password */}
                                                    <div className="">
                                                        <Label>
                                                            Password <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2 items-center">
                                                            <FormikInput
                                                                type="text"
                                                                name="password"
                                                                disabled={updatePasswordMutation.isLoading}
                                                            />
                                                            <div
                                                                className="cursor-pointer hover:rotate-12"
                                                                onClick={async () => {
                                                                    if (!hasPermission(PermissionEnum.USER_UPDATE))
                                                                        return;
                                                                    const generatedPassword = generatePassword();
                                                                    await setFieldTouched('password');
                                                                    await setFieldValue('password', generatedPassword);
                                                                    const isError = await handleUpdatePassword(
                                                                        generatedPassword
                                                                    );
                                                                    if (isError) {
                                                                        // await setFieldError('password', '');  // to set error under field
                                                                    }
                                                                }}
                                                            >
                                                                <ReloadIcon />
                                                            </div>
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="password" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* timezone */}
                                                    <div>
                                                        <Label>
                                                            Role <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2">
                                                            <FormikSelect
                                                                name="role"
                                                                options={roleOption}
                                                                // disabled={formik.values.pointedTo !== ''}
                                                            />
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="role" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* designation */}
                                                    <div className="">
                                                        <Label>Designation</Label>

                                                        <FormikInput type="text" name="designation" />
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="designation" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* dob */}
                                                    <div className="">
                                                        <Label>Date of birth</Label>
                                                        <DatePicker
                                                            className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                                                            onChange={(val) => setFieldValue('dob', val)}
                                                            value={values?.dob || undefined}
                                                            maxDate={new Date()}
                                                        />
                                                        {/* <FormikDatePicker name="dob" /> */}
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="dob" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* mobile number */}
                                                    <div className="">
                                                        <Label>Phone Number</Label>
                                                        <PhoneDetails />
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="phone.number" />
                                                        </FormHelperText>
                                                    </div>
                                                    {/* timezone */}
                                                    <div>
                                                        <Label>
                                                            Timezone <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2">
                                                            <FormikSelect
                                                                name="preferences.timezone"
                                                                options={timezoneOption}
                                                                // disabled={formik.values.pointedTo !== ''}
                                                            />
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="preferences.timezone" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* status */}
                                                    <div>
                                                        <Label>
                                                            Status <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2">
                                                            <FormikSelect
                                                                name="status"
                                                                options={statusOption}
                                                                // disabled={formik.values.pointedTo !== ''}
                                                            />
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="status" />
                                                        </FormHelperText>
                                                    </div>

                                                    {/* primary color */}
                                                    <div className="">
                                                        <Label>Primary Color</Label>
                                                        <ColorPicker
                                                            value={values.preferences?.primaryColor}
                                                            setColor={(event: ColorResult) => {
                                                                setFieldValue(
                                                                    'preferences.primaryColor',
                                                                    event.hex.toUpperCase()
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {/* theme */}
                                                    <div className="">
                                                        <Label>
                                                            Theme <RequiredMark />
                                                        </Label>
                                                        <div className="flex gap-2">
                                                            <FormikSelect
                                                                name="preferences.theme"
                                                                options={themeOption}
                                                                // disabled={formik.values.pointedTo !== ''}
                                                            />
                                                        </div>
                                                        <FormHelperText variant={'error'}>
                                                            <ErrorMessage name="preferences.theme" />
                                                        </FormHelperText>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 gap-4 mt-4">
                                                    <div className="">
                                                        <CustomSwitch
                                                            name="preferences.isEmailNotificationEnabled"
                                                            label="Email Notification"
                                                        />
                                                        <FormHelperText variant={'error'} />
                                                    </div>
                                                    <div className="">
                                                        <CustomSwitch name="preferences.show4k" label="Show 4K" />
                                                        <FormHelperText variant={'error'} />
                                                    </div>
                                                    <div className="">
                                                        <CustomSwitch
                                                            name="preferences.showZoomed"
                                                            label="Show Zoomed"
                                                        />
                                                        <FormHelperText variant={'error'} />
                                                    </div>
                                                    <div className="">
                                                        <CustomSwitch
                                                            name="preferences.showTimestamp"
                                                            label="Show Timestamp"
                                                        />
                                                        <FormHelperText variant={'error'} />
                                                    </div>
                                                </div>

                                                <div>
                                                    <Label>Tags</Label>
                                                    <FieldArray
                                                        name="tags"
                                                        render={(arrayHelpers) => {
                                                            return (
                                                                <div className="">
                                                                    <div className="">
                                                                        <Input
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="Add tag"
                                                                            value={tagHolder}
                                                                            onKeyDown={(
                                                                                event: React.KeyboardEvent<HTMLElement>
                                                                            ) => {
                                                                                if (event.key === 'Enter') {
                                                                                    const trimmedValue =
                                                                                        tagHolder.trim();
                                                                                    if (trimmedValue !== '') {
                                                                                        arrayHelpers.push(trimmedValue);
                                                                                        setTagHolder(() => '');
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }
                                                                            }}
                                                                            onInput={(event: any) => {
                                                                                setTagHolder(event.target.value);
                                                                            }}
                                                                            onBlur={() => {
                                                                                setTagHolder('');
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-wrap gap-2 mt-4  ">
                                                                        {values.tags.map((_, index) => (
                                                                            <Chip
                                                                                key={index}
                                                                                label={values.tags[index]}
                                                                                variant={'default'}
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                }}
                                                                                className="cursor-pointer"
                                                                                icon={
                                                                                    <CrossIcon width={8} height={8} />
                                                                                }
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    />

                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="timezone" />
                                                    </FormHelperText>
                                                </div>
                                            </div>

                                            <Button
                                                disabled={editUserForm.isSubmitting || isValidating}
                                                type="submit"
                                                size={'lg'}
                                                className="mt-8 w-28"
                                            >
                                                {editUserForm.isSubmitting ? (
                                                    <CircularProgress size={20} color={'inherit'} />
                                                ) : isValidating ? (
                                                    'Validating'
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                    <Card className="mt-4">
                        <Card.Header>Project Access</Card.Header>
                        <Card.Body>
                            <ProjectAccessListForm />
                        </Card.Body>
                    </Card>
                    <Card className="mt-4">
                        <Card.Header>External Project</Card.Header>
                        <Card.Body>
                            <ExternalProjectList user={user} />
                        </Card.Body>
                    </Card>
                </div>
            )}
        </PageTemplate>
        // </Drawer>
    );
};

export default UserEditFrom;
